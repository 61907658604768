/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
  
}


::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  background: #7a7f9d;
}


::-webkit-scrollbar-thumb {
  background: #5164a1;
}



/*.ant-table table { border-spacing: 0 10px; }*/



/* 
.slider-container {
  margin: 1.5rem 2.5rem;
  height: 200px;
width: 100px;
}



.slider-image{
  width: 100%;
  height: 100%; 
}
.slider-video {
  width: 100%;
  height: 100%; 
}
.delete-slider-button {
  position: absolute;
  top: 5px;
  right: 5px;
} */

.slider-item {

  height: 100%; 
  margin:auto ;
/* max-height: 450px; */
}
.slider-video {
  width: 100%;
  max-height: 450px; 
  object-fit: cover !important;
}
.slider-image{
  width: 100%;
  height: 100%; 
}
